
import {
    computed, defineComponent, PropType, reactive, watch, nextTick, ref,
} from 'vue';
import clone from 'lodash.clone';
import Location from '@/domain/Location';
import LocationAddress from '@/domain/LocationAddress';
import { LocationAddressType, allAddressTypeOptions, AddressTypeSelectItem } from '@/domain/enums/LocationAddressType';
import { TableDefinition } from '@/types';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import SelectInput from '@/components/inputs/SelectInput.vue';
import LocationAddressService from '@/services/LocationAddressService';
import BRow from '@/components/bootstrap-library/BRow.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { PlaceAutocompleteResult } from '@/components/PlacesAutocomplete.vue';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import Permissions from '@/services/permissions/Permissions';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

type AddressModal = {
    address: LocationAddress;
    show: boolean;
    title: string;
};

type State = {
    addresses: Array<LocationAddress>;
    addressTypes: Array<AddressTypeSelectItem>;
    saving: boolean;
};

export default defineComponent({
    name: 'location-addresses',
    components: {
        SelectInput,
        BRow,
        BAdvancedTable,
        BSpinner,
    },
    props: {
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
        readonly: Boolean,
    },
    emits: ['updateList'],
    setup(props, context) {
        const locationAddressService = new LocationAddressService();
        const show = ref(true);

        const { confirm } = useDialogBox();
        const { validateForm, validationResult, clearResults } = useValidator<LocationAddress>('update-location-address');

        const state = reactive<State>({
            addresses: props.location.locationAddresses?.slice() ?? [],
            addressTypes: allAddressTypeOptions,
            saving: false,
        });

        const addressModal = reactive<AddressModal>({
            show: false,
            address: new LocationAddress({ locationId: props.location.id }),
            title: '',
        });

        function onSelectPlace(value: PlaceAutocompleteResult) {
            // stop if no valid data returned
            if (Object.keys(value).length === 0) return;

            addressModal.address.streetAddress = `${value.street_number ?? ''} ${value.street_name ?? ''}`.trim();
            addressModal.address.zip = value.postal_code;
            addressModal.address.state = value.state;
            addressModal.address.city = value.city;
            addressModal.address.country = value.country;

            show.value = false;
            nextTick(() => {
                show.value = true;
            });
        }

        watch(
            () => props.location.locationAddresses,
            (addresses) => {
                state.addresses = addresses?.slice() ?? [];
            },
        );

        // dont allow add if there are 2 addresses (one ship, one receive), or if there is one for ship+receive
        const canAdd = computed(
            (): boolean => state.addresses.length < 2 && !state.addresses.some((x) => x.type === LocationAddressType.ShippingAndReceiving),
        );

        const addressTableData = computed((): Array<LocationAddress> => state.addresses.map((x) => new LocationAddress(x)));

        const table = computed(
            (): TableDefinition<LocationAddress> => ({
                items: addressTableData.value,
                key: 'location-address-table',
                name: getTitleCaseTranslation('core.domain.locationAddresses'),
                columnDefinition: [
                    {
                        key: 'displayName',
                        label: getTitleCaseTranslation('core.domain.displayName'),
                        searchable: true,
                    },
                    {
                        key: 'typeDescription',
                        label: getTitleCaseTranslation('core.domain.type'),
                        searchable: true,
                    },
                    {
                        key: 'streetAddress',
                        label: getTitleCaseTranslation('core.domain.streetAddress'),
                        searchable: true,
                    },
                    {
                        key: 'city',
                        label: getTitleCaseTranslation('core.domain.city'),
                        searchable: true,
                    },
                    {
                        key: 'state',
                        label: getTitleCaseTranslation('core.domain.state'),
                        searchable: true,
                    },
                    {
                        key: 'country',
                        label: getTitleCaseTranslation('core.domain.country'),
                        searchable: true,
                    },
                ],
            }),
        );

        function closeAddressModal() {
            addressModal.show = false;
        }

        function openAddressModal(address?: LocationAddress) {
            clearResults();
            addressModal.address = clone(address) ?? new LocationAddress({ locationId: props.location.id, type: LocationAddressType.ShippingAndReceiving });
            addressModal.title = address?.id ? getTitleCaseTranslation('core.domain.editAddress') : getTitleCaseTranslation('core.domain.newAddress');
            addressModal.show = true;
        }

        function changeType(value: AddressTypeSelectItem) {
            addressModal.address.type = value.value ?? LocationAddressType.ShippingAndReceiving;
        }

        async function deleteAddress(address: LocationAddress) {
            const response = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTranslation('core.common.deletingAnAddressCannotBeUndone'),
            });
            if (!response) {
                return;
            }

            const deleted = await locationAddressService.deleteLocationAddress(address);
            if (deleted) {
                state.addresses = state.addresses.filter((r) => r.id !== address.id);
                context.emit('updateList', state.addresses);
            }
        }

        function updateAddressById(address: LocationAddress) {
            const i = state.addresses.findIndex((x) => x.id === address.id);
            if (i > -1) {
                state.addresses[i] = address;
            }
        }

        async function saveAddress() {
            state.saving = true;
            validateForm(addressModal.address);
            if (validationResult.isValid) {
                if (addressModal.address.id) {
                    const response = await locationAddressService.updateLocationAddress(addressModal.address);
                    if (response) {
                        updateAddressById(addressModal.address);
                        context.emit('updateList', state.addresses);
                        closeAddressModal();
                    }
                } else {
                    const response = await locationAddressService.addNewLocationAddress(addressModal.address);
                    if (response) {
                        state.addresses.push(response);
                        context.emit('updateList', state.addresses);
                        closeAddressModal();
                    }
                }
            }
            state.saving = false;
        }

        return {
            table,
            addressModal,
            openAddressModal,
            saveAddress,
            deleteAddress,
            Permissions,
            onSelectPlace,
            state,
            show,
            validationResult,
            changeType,
            canAdd,
            getTitleCaseTranslation,
            getTranslation,
        };
    },
});
