import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "floor-locations-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_modal, {
      modelValue: _ctx.floorLocationsModal.show,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.floorLocationsModal.show) = $event)),
      centered: "",
      title: _ctx.pageTitle,
      onHidden: _ctx.closeFloorLocationModal
    }, {
      "btn-ok": _withCtx(() => [
        _createVNode(_component_b_button, {
          variant: "primary",
          onClick: _ctx.saveFloorLocation
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_b_form, null, {
          default: _withCtx(() => [
            _createVNode(_component_b_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.floorLocation.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.floorLocation.name) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.floorLocation'),
                  md: "4",
                  error: _ctx.validationResult.model.name
                }, null, 8, ["modelValue", "label", "error"]),
                _createVNode(_component_text_input, {
                  modelValue: _ctx.state.floorLocation.dimensions,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.floorLocation.dimensions) = $event)),
                  label: _ctx.getTitleCaseTranslation('core.domain.dimensions'),
                  md: "4",
                  error: _ctx.validationResult.model.dimensions
                }, null, 8, ["modelValue", "label", "error"]),
                _createVNode(_component_checkbox_input, {
                  modelValue: _ctx.state.floorLocation.pickable,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.floorLocation.pickable) = $event)),
                  md: "2",
                  label: _ctx.getTitleCaseTranslation('core.domain.pickable')
                }, null, 8, ["modelValue", "label"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue", "title", "onHidden"]),
    _createVNode(_component_b_advanced_table, {
      "table-array": [_ctx.table],
      "sticky-header": "800px",
      style: {"margin":"20px","font-size":"13px"},
      "dynamic-columns": ""
    }, {
      toprow: _withCtx(() => [
        _createVNode(_component_b_button, {
          size: "sm",
          variant: "tertiary",
          disabled: !_ctx.Permissions.ADMINISTRATION.canEditLocations() || _ctx.readonly || _ctx.loading,
          "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? null : _ctx.getTranslation('core.common.youDoNotHavePermissionToAddFloorLocations'),
          onClick: _ctx.openAddFloorLocationModal
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addFloorLocation')), 1)
          ]),
          _: 1
        }, 8, ["disabled", "disabled-message", "onClick"])
      ]),
      action: _withCtx((row) => [
        _createVNode(_component_b_dropdown, {
          "no-caret": "",
          "close-on-click": ""
        }, {
          "button-content": _withCtx(() => [
            _createVNode(_component_faicon, { icon: "ellipsis-h" })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_b_dropdown_item, {
              disabled: !_ctx.Permissions.ADMINISTRATION.canEditLocations() || _ctx.readonly,
              "disabled-message": _ctx.Permissions.ADMINISTRATION.canEditLocations() ? null : _ctx.getTranslation('core.common.youDoNotHavePermissionToEditFloorLocations'),
              onClick: () => _ctx.openEditFloorLocationModal(row.item)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
              ]),
              _: 2
            }, 1032, ["disabled", "disabled-message", "onClick"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["table-array"])
  ]))
}