
import {
    computed, defineComponent, PropType, reactive,
} from 'vue';
import FloorLocation from '@/domain/FloorLocation';
import BAdvancedTable from '@/components/bootstrap-library/table/BAdvancedTable.vue';
import FloorLocationService from '@/services/FloorLocationService';
import { TableDefinition } from '@/types';
import useValidator from '@/validation/useValidator';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import Permissions from '@/services/permissions/Permissions';
import Location from '@/domain/Location';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';

type FloorLocationModal = {
    show: boolean;
};

type State = {
    floorLocation: FloorLocation;
    originalFloorLocationName: string;
};

export default defineComponent({
    name: 'location-floor-location',
    components: {
        BDropdownItem,
        BDropdown,
        BAdvancedTable,
    },
    props: {
        floorLocations: {
            type: Array as PropType<Array<FloorLocation>>,
            default: () => [],
            required: true,
        },
        location: {
            type: Object as PropType<Location>,
            required: true,
        },
        readonly: Boolean,
        loading: Boolean,
    },
    emits: ['updateList'],

    setup(props, context) {
        const floorLocationService = new FloorLocationService();

        const state = reactive<State>({
            floorLocation: new FloorLocation(),
            originalFloorLocationName: '',
        });

        const floorLocationsModal = reactive<FloorLocationModal>({
            show: false,
        });

        const { validateForm, validationResult, clearResults } = useValidator<FloorLocation>('floor-location');

        const pageTitle = computed(() => (state.floorLocation.id ? getTitleCaseTranslation('core.domain.editFloorLocation') : getTitleCaseTranslation('core.button.addFloorLocation')));

        const { confirm } = useDialogBox();

        const table = computed(
            (): TableDefinition<any> => ({
                items: props.floorLocations.map((data: FloorLocation) => ({
                    id: data.id,
                    name: data.name,
                    dimensions: data.dimensions,
                    pickable: data.pickable,
                    parentLocationId: data.parentLocationId,
                })),
                key: 'floor-location-table',
                name: getTitleCaseTranslation('core.domain.floorLocations'),
                columnDefinition: [
                    {
                        key: 'name',
                        label: getTitleCaseTranslation('core.domain.name'),
                        searchable: true,
                    },
                    {
                        key: 'dimensions',
                        label: getTitleCaseTranslation('core.domain.dimensions'),
                        searchable: true,
                    },
                    {
                        key: 'pickable',
                        label: getTitleCaseTranslation('core.domain.pickable'),
                        searchable: true,
                    },
                ],
            }),
        );

        function closeFloorLocationModal() {
            floorLocationsModal.show = false;
        }

        async function saveFloorLocation() {
            validateForm(state.floorLocation);
            if (validationResult.isValid) {
                if (state.floorLocation.isNew) {
                    state.floorLocation.parentLocationId = props.location.id;
                    const response = await floorLocationService.addNewFloorLocation(state.floorLocation);
                    if (response.success) {
                        context.emit('updateList', [...props.floorLocations, new FloorLocation(response.floorLocation)]);
                        closeFloorLocationModal();
                    }
                } else {
                    if (state.floorLocation.name !== state.originalFloorLocationName && !(await confirm({
                        message: getTranslation('core.common.areYouSureYouWantToRenameFloorLocationName'),
                        title: getTranslation('core.common.areYouSure'),
                    }))) {
                        return;
                    }
                    const response = await floorLocationService.updateFloorLocation(state.floorLocation);
                    if (response) {
                        const floorLocationArray = props.floorLocations.filter((floorLocation) => floorLocation.id !== response.floorLocation.id);
                        floorLocationArray.push(new FloorLocation(state.floorLocation));
                        context.emit('updateList', floorLocationArray);
                        closeFloorLocationModal();
                    }
                }
            }
        }

        function openAddFloorLocationModal() {
            clearResults();
            state.floorLocation = new FloorLocation();
            floorLocationsModal.show = true;
        }

        function openEditFloorLocationModal(floorLocation: FloorLocation) {
            clearResults();
            floorLocationsModal.show = true;
            state.originalFloorLocationName = floorLocation.name;
            state.floorLocation = JSON.parse(JSON.stringify(floorLocation));
        }

        return {
            state,
            table,
            getTitleCaseTranslation,
            getTranslation,
            validationResult,
            floorLocationsModal,
            pageTitle,
            closeFloorLocationModal,
            saveFloorLocation,
            Permissions,
            openAddFloorLocationModal,
            openEditFloorLocationModal,
        };
    },
});
