import axios, { AxiosResponse } from 'axios';
import SortStation from '@/domain/SortStation';
import ApplicationUser from '@/domain/ApplicationUser';
import { DataAccessResponse } from '@/types/api/DataAccessResponse';
import { DataAccessResponseClass } from '@/services/api/dataAccessResponse';
import { API_SERVICE_URL } from '@/config/env';

export default class SortStationApiService {
    public async createSortStation(sortStation: SortStation): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'post',
            data: sortStation,
            url: `${API_SERVICE_URL}/sortstation`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async updateSortStation(sortStation: SortStation): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'put',
            data: sortStation,
            url: `${API_SERVICE_URL}/sortstation`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async deleteSortStation(sortStation: SortStation): Promise<DataAccessResponse<number>> {
        const response: AxiosResponse<DataAccessResponse<number>> = await axios({
            method: 'delete',
            data: sortStation,
            url: `${API_SERVICE_URL}/sortstation`,
        });

        return new DataAccessResponseClass<number>(response).response;
    }

    public async getSortStationsByLocationIdWithActivity(locationId: number): Promise<DataAccessResponse<Array<SortStation>>> {
        const response: AxiosResponse<DataAccessResponse<Array<SortStation>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/sortstation/activity/${locationId}`,
        });

        return new DataAccessResponseClass<Array<SortStation>>(response).response;
    }

    public async getUsers(locationId: number): Promise<DataAccessResponse<Array<ApplicationUser>>> {
        const response: AxiosResponse<DataAccessResponse<Array<ApplicationUser>>> = await axios({
            method: 'GET',
            url: `${API_SERVICE_URL}/sortstation/users/${locationId}`,
        });

        return new DataAccessResponseClass<Array<ApplicationUser>>(response).response;
    }

    public async connectToSortStation(sortStationId: number, userId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            data: { sortStationId, userId },
            url: `${API_SERVICE_URL}/sortstation/connect`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }

    public async disconnectFromSortStation(sortStationId: number, userId: number): Promise<DataAccessResponse<boolean>> {
        const response: AxiosResponse<DataAccessResponse<boolean>> = await axios({
            method: 'PUT',
            data: { sortStationId, userId },
            url: `${API_SERVICE_URL}/sortstation/disconnect`,
        });

        return new DataAccessResponseClass<boolean>(response).response;
    }
}
