export default class FloorLocation {
    id: number = 0;

    name: string = '';

    dimensions?: string;

    parentLocationId!: number;

    parentLocation!: Location;

    pickable!: boolean;

    constructor(init?: Partial<FloorLocation>) {
        Object.assign(this, init);
        this.parentLocationId = init?.parentLocationId ?? 0;
        this.pickable = init?.pickable ?? true;
    }

    get isNew(): boolean {
        return this.id === 0;
    }
}
